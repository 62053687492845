import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Welcome!`}</h1>
    <p>{`This website is under construction, please come back later.`}</p>
    <h3>{`Get in touch`}</h3>
    <p>{`Follow us on `}<a parentName="p" {...{
        "href": "https://www.twitter.com/MirrorGallery_"
      }}>{`Twitter`}</a>{` & `}<a parentName="p" {...{
        "href": "https://www.instagram.com/mirror.gallery.art"
      }}>{`Instagram`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      